$primary: #2a2a2a;
$menu-item: #fff;

html,
body {
  font-family: Poppins, Nunito, sans-serif !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
}

/*Scrollbar CSS*/
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}
::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px; /*Prevent it from getting too small */
}
::-webkit-scrollbar-thumb:vertical:active {
  /*background: rgba(0,0,0,0.61); /* Some darker color when you click it*/
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px; /*Prevent it from getting too small */
}
::-webkit-scrollbar-thumb:horizontal:active {
  /*background: rgba(0,0,0,0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
/*------------END-----------*/

.fw {
  width: 100%;
}
.fh {
  height: 100vh;
}

.m5 {
  margin: 5px;
}
.m10 {
  margin: 10px;
}
.m15 {
  margin: 15px;
}
.m20 {
  margin: 20px;
}

.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr20 {
  margin-right: 20px;
}

.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}

.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}

.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}

.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}

.pr5 {
  padding-right: 5px;
}
.pr10 {
  padding-right: 10px;
}
.pr15 {
  padding-right: 15px;
}
.pr20 {
  padding-right: 20px;
}

.pl5 {
  padding-left: 5px;
}
.pl10 {
  padding-left: 10px;
}
.pl15 {
  padding-left: 15px;
}
.pl20 {
  padding-left: 20px;
}

.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt15 {
  padding-top: 15px;
}
.pt20 {
  padding-top: 20px;
}

.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb15 {
  padding-bottom: 15px;
}
.pb20 {
  padding-bottom: 20px;
}
.nm {
  margin: 0 !important;
}
.np {
  padding: 0 !important;
}

.f10 {
  font-size: 10px !important;
}
.f15 {
  font-size: 15px !important;
}
.f20 {
  font-size: 20px !important;
}
.f25 {
  font-size: 25px !important;
}

.error {
  color: var(--magenta-60);
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  margin-top: var(--spacing-xs);
}

.pointer {
  cursor: pointer;
}
.label {
  color: var(--primary-opacity-80);
  font-weight: 600;
}
// .custom-input.ant-input,
// .custom-input .ant-input,
// .custom-input > input[type="text"],
// .custom-input input,
// .custom-input.ant-picker,
// textarea.custom-input {
//   background: #f8fafb !important;
//   border-color: transparent !important;
//   font-size: 14px !important;
//   padding: 6px 16px;
//   box-shadow: none;
//   border-radius: 4px;
// }

// .custom-input {
//   .ant-input-group {
//     .ant-input-group-addon {
//       border-color: transparent !important;
//     }
//   }
// }
// .ant-input-affix-wrapper {
//   border-color: transparent !important;
//   background: #f8fafb !important;
// }
// .custom-input.ant-input-affix-wrapper {
//   background: #f8fafb !important;
//   border-color: transparent;
//   box-shadow: none;
//   border-radius: 4px;
// }
// .custom-select > .ant-select-selector {
//   background-color: #f8fafb !important;
//   border-color: transparent !important;
//   border-radius: 4px !important;
//   font-size: 14px;
//   box-shadow: none !important;
// }
// .custom-input.white .ant-input,
// .custom-input.white {
//   background-color: #fff !important;
// }
// .ant-modal-header {
//   background: $primary !important;
//   border-bottom: 0 !important;
// }
.ant-btn-default {
  color: var(--primary-opacity-80) !important;
  border-color: var(--primary-opacity-20) !important;
}

.ant-btn-default:hover {
  color: var(--primary-opacity-80) !important;
}

.ant-btn-success {
  background-color: var(--primary-open-ai) !important;
  border-color: var(--primary-open-ai) !important;
  color: var(--white) !important;
}
.ant-btn-success:disabled,
.ant-btn-success:disabled:hover {
  background: var(--color-green-300) !important;
  border-color: var(--color-green-300) !important;
  color: var(--gray-1) !important;
}

.ant-btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}
.ant-btn-primary:disabled,
.ant-btn-primary:disabled:hover {
  background: #727272 !important;
  color: var(--gray-1) !important;
  border-color: #727272 !important;
}

.ant-btn-primary-outline {
  background: transparent !important;
  border-color: $primary !important;
  color: $primary !important;
}

.ant-btn-primary-outline:disabled,
.ant-btn-primary-outline:disabled:hover {
  border-color: var($primary) !important;
  color: var($primary) !important;
}

.ant-btn-primary-link {
  color: $primary !important;
  border: 0 !important;
  background: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  span {
    text-decoration: underline;
  }
}
.ant-btn-primary-link:disabled,
.ant-btn-primary-link:disabled:hover {
  color: var(--primary-opacity-60) !important;
}

.ant-btn-link {
  color: $primary !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;

  span {
    text-decoration: underline;
  }
}
.ant-btn-link:disabled,
.ant-btn-link:disabled:hover {
  color: var(--blue-4) !important;
}

.ant-btn {
  border-radius: 5px !important;
}
.ant-btn-circle {
  border-radius: 50% !important;
}
button[display="none"] {
  display: none;
}
.ant-image-img.cover {
  object-fit: cover;
}

.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: $primary !important;
}

.ant-menu-item a:hover {
  color: $menu-item !important;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu-selected,
.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: $menu-item !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
  border-right: 3px solid $menu-item !important;
}

.capitalize {
  text-transform: capitalize;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

// table styles start
.tr-container {
  width: 100%;
  background-color: var(--white);

  padding: var(--spacing-m);
  margin-bottom: var(--spacing-5);

  color: var(--gray-9);

  border-radius: 0.3rem;
  border: 1px solid var(--color-gray-200);
}
// table styles end

// antd steps style start
.ant-steps {
  .ant-steps-item {
    .ant-steps-item-tail,
    .ant-steps-item-title {
      font-weight: 600;

      &::after {
        // height: var(--border-2);
        background-color: var(--secondary) !important;
      }
    }
  }

  .ant-steps-item-finish,
  .ant-steps-item-process {
    .ant-steps-item-icon {
      background-color: var(--primary) !important;
      border-color: var(--primary) !important;
    }

    .ant-steps-icon {
      color: var(--white) !important;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-tail,
    .ant-steps-item-title {
      &::after {
        background-color: var(--primary) !important;
      }
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background-color: var(--secondary) !important;
      border-color: var(--secondary) !important;
    }

    .ant-steps-icon {
      color: var(--white) !important;
    }

    .ant-steps-item-title {
      color: var(--secondary) !important;
    }
  }
}
// antd steps style end

// antd checkox style start
.ant-checkbox {
  .ant-checkbox-inner {
    border-color: var(--primary) !important;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
  }
}

.ant-checkbox-disabled {
  .ant-checkbox-inner {
    background-color: var(--primary-opacity-10) !important;
  }
}
// antd checkox style end

// custom steps response style end
.steps-container-horizontal {
  display: none;
}

.steps-container-vertical {
  display: flex;
}

@media (min-width: 1200px) {
  .steps-container-horizontal {
    display: flex;
  }

  .steps-container-vertical {
    display: none;
  }
}
// custom steps response style end
