.ant-select.query-select-tag .ant-select-selector {
    background-color: #2a2a2a;
    color: white;
  
  }
  .query-select-tag{
    width: 150px;
    text-align: center;
    color: white;

  }
  .query-select-tag.ant-select.ant-select-lg:not(.ant-select-disabled):hover .ant-select-selector {
    color: white !important;
  }
  
  .query-select-tag.ant-select.ant-select-lg .ant-select-selection-item {
    color: white !important;
  }
  .ant-table-thead th {
    min-height: 60px;
    font-size: larger;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)!important;
  }
  .reset-btn{
    width: 95px;
  }