.ant-layout-header.appHeader {
  height: 70px;
  position: sticky;
  top: 0;
  z-index: 2;
  background: var(--white) !important;
  padding: 0 !important;
  line-height: 0 !important;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

  .ant-popover,
  .ant-popover-placement-bottom {
    position: relative;
    top: 21px;
    left: -260px;
    .ant-popover-content {
      width: 310px;
      position: relative;
      top: 21px;
      left: -260px;
      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }
  .notification-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    text-decoration-line: underline;
    color: var(--primary);
    opacity: 0.8;
    span {
      border-bottom: 0.1px solid #949494;
    }
  }
  .notifications {
    .notification {
      padding: 15px 30px;
      border-bottom: 1px solid #eaeaea;
    }
  }

  .vertical-divider {
    border-left: 1px solid #d9d9d9;
    height: 3em;
    margin-left: 25px;
  }
  .nowrap {
    display: flex;
    width: max-content;
    flex-wrap: nowrap;
  }

  .info-contianer {
    flex: 1 1 100%;
    min-width: 0;
    p {
      margin: 0;
      font-weight: 400;
      font-size: 15px;
      line-height: 30px;
      color: #000000;

      span{
        text-transform: capitalize;
      }
    }
  }

  .user-info-container {
    max-width: 300px;
    line-height: 18px;
    flex-flow: unset !important;

    .name-container {
      width: max-content;
      text-overflow: ellipsis;
      overflow: hidden;

      .role {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-transform: capitalize;
        color: #000000;
        opacity: 0.6;
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .info-contianer {
    display: none;
  }
  .user-info-container {
    width: min-content;
  }
}

// .ant-dropdown-menu .ant-dropdown-menu-title-content .anticon {
//   margin-right: 10px;
// }
