.custom-modal-container {
  width: 520px;
  transform-origin: -114px 698.078px;

  .ant-modal-title {
    font-size: 1.45rem !important;
    font-weight: 700;
  }

  .ant-modal-header,
  .ant-modal-content {
    border-radius: 8px !important;
  }
}
